import {useLocation} from "react-router-dom";
import {
    BUILD_VERSION,
    CDN_LINK,
} from "@globalConfig";
import {onPushDataLayerNew} from "@/utils/events";
import stl from "./Aside.module.css";
import getMenuData from "./menuData";
import {useLang} from "@/common/Contexts/LanguageContext";
import {useTranslation} from "@i18n";
import ProgressiveImage from "@componentsShared/ProgressiveImage";
import {URL_SELL_TRADE_LANDING} from "@/pages/module-sell-trade/SellTradeContext";
import {getPlatformInfo} from "@/utils/GlobalJavascriptFunction";
import {trackEvents} from "@/common/events/events";
import useHelpers from "@hooks/useHelpers";
import {useAuthActions, useSession} from "@/_MiniApps/auth/AuthContext";
import useSideMenuController, {dispatchSideMenuToggle as toggleMenu} from "@/_LayoutsRelated/layoutHooks/useSideMenuController";

const NewAside =
    ({
         switchLink
    }) => {

    const lang = useLang();
    const {createUrl, createUrl2, navigateToHard} = useHelpers();
    const {t} = useTranslation(["web_aside_menu"]);
    const {isLoggedIn, user} = useSession();
    const {logout} = useAuthActions();
    const showMenu = useSideMenuController();


    const location = useLocation();
    const menuDataFull = getMenuData(
        t, lang,
        {isLoggedIn, user, logout},
        {createUrl, navigateToHard}).filter(
        (m) => m.show
    );
    return (
        <aside
            className={[stl.menu, showMenu ? stl.showMenu : undefined].join(" ")}>
            <div className={stl.menuContent}>
                <header>
          <span onClick={toggleMenu}>
            <ProgressiveImage
                waitUntilInteractive
                width={16}
                height={16}
                src={CDN_LINK + "/assets/images/close_gry.svg"}
                alt="close"
            />
          </span>
                    <a
                        href={createUrl(URL_SELL_TRADE_LANDING)}
                        onClick={() => {
                            trackEvents("sell_car_started", {
                                referral_location: "side_menu",
                                platform: getPlatformInfo(),
                            });
                        }}
                    >
                        {t("بدل/بيعنا سيارتك", {
                            ns: "web_aside_menu",
                        })}
                    </a>
                </header>
                <div className={stl.menuBody}>
                    <section className={stl.logInSection}>
                        <div className={stl.linksWrapper}>
                            {!isLoggedIn && (
                                <a
                                    href={createUrl("/user/signup")}
                                    className={`${stl.link} ${stl.withBorder}`}>
                                    {t("تسجيل الدخول/إنشاء حساب", {
                                        ns: "web_aside_menu",
                                    })}
                                </a>
                            )}

                            {isLoggedIn && !user?.is_dealer && (
                                <strong className={stl.userName}>
                                    {t("أهلاً", {
                                        ns: "web_aside_menu",
                                    })}{" "}
                                    {user?.full_name
                                        ? user?.full_name
                                        : t("بك", {
                                            ns: "web_aside_menu",
                                        })}{" "}
                                </strong>
                            )}
                        </div>
                        <a
                            className={stl.langSwitch}
                            href={
                                switchLink
                                    ? switchLink
                                    : createUrl2(location.pathname+location.search)
                            }>
                            <span>{lang !== "ar" ? "عربي" : "English"}</span>
                            <ProgressiveImage
                                waitUntilInteractive
                                src={CDN_LINK + "/assets/images/globe.svg"}
                                alt="globe"
                                width={16}
                                height={16}
                                loading="lazy"
                            />
                        </a>
                    </section>

                    {menuDataFull.map((mainMenu, idx) => {
                        return (
                            <section
                                key={mainMenu.sectionName}
                                className={[
                                    stl.menuSection,
                                    // idx === 0 ? "m-hide" : undefined,
                                ].join(" ")}>
                                <strong className={stl.title}>
                                    {t(mainMenu.sectionName, {
                                        ns: "web_aside_menu",
                                    })}
                                </strong>
                                <ul>
                                    {mainMenu.subLinks
                                        .filter((m) => !m.hide)
                                        .map((menuItem) => (
                                            <li key={menuItem.link}>
                                                <a
                                                    style={{cursor: 'pointer'}}
                                                    target={menuItem.isExternal ? "_blank" : undefined}
                                                    href={menuItem.link}
                                                    onClick={(e) => {
                                                        menuItem.linkFunc?.(e);
                                                    }}>
                                                    <ProgressiveImage
                                                        waitUntilInteractive
                                                        width={16}
                                                        height={16}
                                                        style={{
                                                            transform: menuItem.scale
                                                                ? menuItem.scale
                                                                : undefined,
                                                        }}
                                                        src={menuItem.imageBlue}
                                                        alt="icon"
                                                    />
                                                    <ProgressiveImage
                                                        waitUntilInteractive
                                                        width={16}
                                                        height={16}
                                                        style={{
                                                            transform: menuItem.scale
                                                                ? menuItem.scale
                                                                : undefined,
                                                        }}
                                                        src={menuItem.image}
                                                        alt="icon"
                                                    />
                                                    <span>{menuItem.title}</span>
                                                </a>
                                            </li>
                                        ))}
                                </ul>
                            </section>
                        );
                    })}

                    <div className={stl.menuFooter}>
                        <strong className={"greenGrd"}>
                            <ProgressiveImage
                                waitUntilInteractive
                                width={15}
                                height={17}
                                src={CDN_LINK + "/assets/images/greenArmor.svg"}
                                alt=""
                            />
                            <span>
                {t("طرق دفع إلكترونية آمنة", {
                    ns: "web_aside_menu",
                })}
              </span>
                        </strong>
                        <ul className={["payment", stl.menuPayments].join(" ")}>
                            <li>
                <span className="icon-Mada" style={{fontSize: "44px"}}>
                  <span className="path1"/>
                  <span className="path2"/>
                  <span className="path3"/>
                  <span className="path4"/>
                  <span className="path5"/>
                  <span className="path6"/>
                  <span className="path7"/>
                  <span className="path8"/>
                  <span className="path9"/>
                </span>
                            </li>
                            <li>
                <span className="icon-Visadark" style={{fontSize: "44px"}}>
                  <span className="path1"/>
                  <span className="path2"/>
                  <span className="path3"/>
                  <span className="path4"/>
                  <span className="path5"/>
                  <span className="path6"/>
                </span>
                            </li>
                            <li>
                <span className="icon-mc" style={{fontSize: "44px"}}>
                  <span className="path1"/>
                  <span className="path2"/>
                  <span className="path3"/>
                  <span className="path4"/>
                  <span className="path5"/>
                  <span className="path6"/>
                </span>
                            </li>
                            <li>
                <span className="icon-apple_pay" style={{fontSize: "44px"}}>
                  <span className="path1"/>
                  <span className="path2"/>
                </span>
                            </li>

                            <li>
                <span className="icon-Sadad" style={{fontSize: "44px"}}>
                  <span className="path1"/>
                  <span className="path2"/>
                  <span className="path3"/>
                  <span className="path4"/>
                </span>
                            </li>
                            <li>
                <span
                    className="icon-BankTransfer"
                    style={{fontSize: "44px"}}>
                  <span className="path1"/>
                  <span className="path2"/>
                  <span className="path3"/>
                  <span className="path4"/>
                  <span className="path5"/>
                </span>
                            </li>
                        </ul>
                        <div className={stl.socialMedia}>
              <span className={stl.title}>
                {t("تابعنا لتصلك آخر عروض السيارات", {
                    ns: "web_aside_menu",
                })}
              </span>
                            {showMenu && (
                                <ul>
                                    <li>
                                        <a
                                            href="https://www.facebook.com/syarah.KSA"
                                            onClick={() => {
                                                onPushDataLayerNew(
                                                    "syarah_events",
                                                    "socialClicks",
                                                    "facebook"
                                                );
                                            }}
                                            rel="noopener noreferrer"target="_blank">
                                            <ProgressiveImage
                                                waitUntilInteractive
                                                width={22}
                                                height={22}
                                                src={CDN_LINK + "/assets/images/Facebook@1.svg"}
                                                alt="facebook icon"
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://twitter.com/Syarah_ksa"
                                            onClick={() => {
                                                onPushDataLayerNew(
                                                    "syarah_events",
                                                    "socialClicks",
                                                    "twitter"
                                                );
                                            }}
                                            rel="noopener noreferrer"target="_blank">
                                            <ProgressiveImage
                                                waitUntilInteractive
                                                width={22}
                                                height={22}
                                                src={CDN_LINK + "/assets/images/Twitter@1.svg"}
                                                alt="twitter icon"
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.instagram.com/Syarah_ksa/"
                                            onClick={() => {
                                                onPushDataLayerNew(
                                                    "syarah_events",
                                                    "socialClicks",
                                                    "instagram"
                                                );
                                            }}
                                            rel="noopener noreferrer"target="_blank">
                                            <ProgressiveImage
                                                waitUntilInteractive
                                                width={22}
                                                height={22}
                                                src={CDN_LINK + "/assets/images/Instagram@1.svg"}
                                                alt="instagram icon"
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.snapchat.com/add/syarah_ksa"
                                            onClick={() => {
                                                onPushDataLayerNew(
                                                    "syarah_events",
                                                    "socialClicks",
                                                    "snapchat"
                                                );
                                            }}
                                            rel="noopener noreferrer"target="_blank">
                                            <ProgressiveImage
                                                waitUntilInteractive
                                                width={22}
                                                height={22}
                                                src={CDN_LINK + "/assets/images/Snapchat@1.svg"}
                                                alt="snapchat icon"
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.youtube.com/channel/UCAceZ20JD3qef5OQSVc95Bw"
                                            onClick={() => {
                                                onPushDataLayerNew(
                                                    "syarah_events",
                                                    "socialClicks",
                                                    "youtube"
                                                );
                                            }}
                                            rel="noopener noreferrer"target="_blank">
                                            <ProgressiveImage
                                                waitUntilInteractive
                                                width={22}
                                                height={22}
                                                src={CDN_LINK + "/assets/images/Youtube@1.svg"}
                                                alt="youtube icon"
                                            />
                                        </a>
                                    </li>
                                </ul>
                            )}
                        </div>
                        <div className={stl.ftrLinks}>
                            <a
                                href={createUrl("/terms-landing")}
                                rel="noopener noreferrer">
                                {t("الأحكام والشروط", {ns: "web_aside_menu"})}
                            </a>{" "}
                            |{" "}
                            <a
                                href={createUrl("/privacy-policy")}
                                rel="noopener noreferrer">
                                {t("سياسة الخصوصية", {ns: "web_aside_menu"})}
                            </a>
                        </div>
                        {BUILD_VERSION && (
                            <div className={stl.buildVer}>
                <span>
                  {t("version", {
                      ns: "web_aside_menu",
                  })}
                    : <strong>{BUILD_VERSION}</strong>
                </span>
                            </div>
                        )}
                    </div>
                    <div className={stl.sejelCont}>
                        <div className={stl.sejelCont2}>
                            <div className={[stl.container, "container"].join(" ")}>
                                <p>
                                    {t(
                                        "شركة موقع سيارة للتجارة شركة موثقة لدى وزارة التجارة والاستثمار وبدعم من شركة علم, بسجل تجاري رقم 1010538980",
                                        {ns: "web_aside_menu"}
                                    )}
                                </p>
                                <div className={stl.footerWithVat}>
                                    <a
                                        className={stl.vatLink}
                                        href={
                                            CDN_LINK +
                                            "/assets/bundles/GAZT_VAT_Certificate.pdf"
                                        }
                                        rel="noopener noreferrer"target="_blank">
                                        <ProgressiveImage
                                            waitUntilInteractive
                                            width={30}
                                            height={38}
                                            src={CDN_LINK + "/assets/bundles/Vat.svg"}
                                            alt="vat icon"
                                        />
                                    </a>
                                    <ProgressiveImage
                                        waitUntilInteractive
                                        src={CDN_LINK + "/assets/bundles/saudi_elm.svg"}
                                        alt="saudi_elm icon"
                                        width={262}
                                        height={44}
                                        loading="lazy"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div onClick={toggleMenu} className={stl.bgForMenu}/>
        </aside>
    );
};

export default NewAside;
