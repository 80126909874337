import _defineProperty from "/usr/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { CDN_LINK } from "@globalConfig";
import { trackEvents } from "@/common/events/events";
import { CARS_PRICES_BASE_PATH } from "@/pages/car-prices/helpers/carsPricesHelpers";
import { dispatchSideMenuOpenClose } from "@/_LayoutsRelated/layoutHooks/useSideMenuController";
var menuData = function menuData(t, lang, _ref, _ref2) {
  var isLoggedIn = _ref.isLoggedIn,
    user = _ref.user,
    logout = _ref.logout;
  var createUrl = _ref2.createUrl,
    navigateToHard = _ref2.navigateToHard;
  return [{
    sectionName: t("حسابي", {
      ns: "web_aside_menu"
    }),
    show: isLoggedIn,
    subLinks: [{
      link: null,
      imageBlue: CDN_LINK + "/assets/images/round-person-24px (2)@1Blue.svg",
      image: CDN_LINK + "/assets/images/round-person-24px (2)@1.svg",
      title: t("معلوماتي", {
        ns: "web_aside_menu"
      }),
      linkFunc: function linkFunc(e) {
        e.preventDefault();
        navigateToHard("/user/account/my-info");
      }
    }, {
      link: createUrl("/tracking-landing"),
      imageBlue: CDN_LINK + "/assets/images/location_on-24px@1Blue.svg",
      image: CDN_LINK + "/assets/images/location_on-24px@1.svg",
      title: t("تتبع طلباتي", {
        ns: "web_aside_menu"
      })
    }, {
      link: createUrl("/user/favorite"),
      imageBlue: CDN_LINK + "/assets/images/favorite-Blue.svg",
      image: CDN_LINK + "/assets/images/aside-favorite.svg",
      title: t("المفضلة", {
        ns: "web_aside_menu"
      }),
      scale: "scale(1.3)"
    }, {
      link: createUrl("/site/saved-search"),
      imageBlue: CDN_LINK + "/assets/images/save-search-icon-Blue.svg",
      image: CDN_LINK + "/assets/images/save-search-icon.svg",
      title: t("بحوثي المحفوظة", {
        ns: "web_aside_menu"
      })
    }, {
      link: createUrl("/site/recently-viewed"),
      imageBlue: CDN_LINK + "/assets/images/recentViesIconBlue.svg",
      image: CDN_LINK + "/assets/images/recentViesIcon.svg",
      title: t("سيارات شوهدت مؤخراً", {
        ns: "web_aside_menu"
      }),
      scale: "scale(1.3)"
    }, {
      link: "#",
      imageBlue: CDN_LINK + "/assets/images/Sign-out-Blue.svg",
      image: CDN_LINK + "/assets/images/round-exit_to_app-24px@1.svg",
      title: t("تسجيل الخروج", {
        ns: "web_aside_menu"
      }),
      scale: "scale(1.3)",
      linkFunc: function linkFunc(e) {
        var _user$phone_number;
        e.preventDefault();
        trackEvents("user_logout", {
          user_id: "966".concat((_user$phone_number = user.phone_number) === null || _user$phone_number === void 0 ? void 0 : _user$phone_number.slice(1)),
          ui_language: lang
        });
        dispatchSideMenuOpenClose(false);
        logout();
      }
    }]
  }, {
    sectionName: t("خدماتنا", {
      ns: "web_aside_menu"
    }),
    show: true,
    subLinks: [{
      link: createUrl("/autos"),
      imageBlue: CDN_LINK + "/assets/images/sideMenu/BlueIcons/all_cars_icon_Blue.svg",
      image: CDN_LINK + "/assets/images/sideMenu/all_cars_icon.svg",
      title: t("سيارات ومركبات", {
        ns: "web_aside_menu"
      }),
      scale: "scale(1.8)"
    }, {
      link: createUrl("/offerspage"),
      imageBlue: CDN_LINK + "/assets/images/sideMenu/BlueIcons/offers_icon_Blue.svg",
      image: CDN_LINK + "/assets/images/sideMenu/offers_icon.svg",
      title: t("عروض السيارات", {
        ns: "web_aside_menu"
      }),
      scale: "scale(1.6)"
    }, {
      link: createUrl("/filters", _defineProperty({}, "condition_id", 0)),
      imageBlue: CDN_LINK + "/assets/images/UsedCarsOffersBlue.svg",
      image: CDN_LINK + "/assets/images/UsedCarsOffers.svg",
      title: t("عروض السيارات المستعملة", {
        ns: "web_aside_menu"
      }),
      scale: "scale(1.3)"
    }, {
      link: createUrl("/filters", _defineProperty({}, "condition_id", 1)),
      imageBlue: CDN_LINK + "/assets/images/NewCarsOffersBlue.svg",
      image: CDN_LINK + "/assets/images/NewCarsOffers.svg",
      title: t("عروض السيارات الجديدة", {
        ns: "web_aside_menu"
      }),
      scale: "scale(1.3)"
    }, {
      link: createUrl("/tracking-landing"),
      imageBlue: CDN_LINK + "/assets/images/location_on-24px@1Blue.svg",
      image: CDN_LINK + "/assets/images/location_on-24px@1.svg",
      title: t("تعقب طلب سيارتك", {
        ns: "web_aside_menu"
      })
    }, {
      link: createUrl("/financing"),
      imageBlue: CDN_LINK + "/assets/images/Finance@1Blue.svg",
      image: CDN_LINK + "/assets/images/Finance@1.svg",
      title: t("تمويل سيارات", {
        ns: "web_aside_menu"
      }),
      scale: "scale(1.1)"
    }, {
      link: createUrl("/financing/calculator"),
      imageBlue: CDN_LINK + "/assets/images/calculate-installment-Blue.svg",
      image: CDN_LINK + "/assets/images/calculate-installment.svg",
      title: t("حاسبة تمويل السيارات", {
        ns: "web_aside_menu"
      }),
      scale: "scale(1.1)"
    }]
  }, {
    sectionName: t("موقع سيارة", {
      ns: "web_aside_menu"
    }),
    show: true,
    subLinks: [{
      link: createUrl("/contact-us"),
      imageBlue: CDN_LINK + "/assets/images/Call Us@1Blue.svg",
      image: CDN_LINK + "/assets/images/Call Us@1.svg",
      title: t("اتصل بنا", {
        ns: "web_aside_menu"
      })
    }, {
      link: createUrl("/about-us"),
      imageBlue: CDN_LINK + "/assets/images/Who We Are@1Blue.svg",
      image: CDN_LINK + "/assets/images/Who We Are@1.svg",
      title: t("من نحن", {
        ns: "web_aside_menu"
      }),
      scale: "scale(1.2)"
    }, {
      link: createUrl("/about-us/services"),
      imageBlue: CDN_LINK + "/assets/images/services_Blue_icon.svg",
      image: CDN_LINK + "/assets/images/services_icon.svg",
      title: t("خدماتنا", {
        ns: "web_aside_menu"
      }),
      scale: "scale(1.5)"
    }, {
      link: createUrl("/trend"),
      imageBlue: CDN_LINK + "/assets/images/sideMenu/BlueIcons/trend_icon_Blue.svg",
      image: CDN_LINK + "/assets/images/sideMenu/trend_icon.svg",
      title: t("سيارة ترند", {
        ns: "web_aside_menu"
      }),
      scale: "scale(1.5)"
    }, {
      link: createUrl("/car-showrooms"),
      imageBlue: CDN_LINK + "/assets/images/sideMenu/BlueIcons/Dealers@1Blue.svg",
      image: CDN_LINK + "/assets/images/sideMenu/Dealers@1.svg",
      title: t("معرض سيارة", {
        ns: "web_aside_menu"
      }),
      scale: "scale(1.5)"
    }, {
      link: createUrl("/city"),
      imageBlue: CDN_LINK + "/assets/images/sideMenu/BlueIcons/cities_icon_Blue.svg",
      image: CDN_LINK + "/assets/images/sideMenu/cities_icon.svg",
      title: t("المدن", {
        ns: "web_aside_menu"
      }),
      scale: "scale(1.5)"
    }, {
      link: "https://syarah.com/carsguide/".concat(lang === 'en' ? 'en/' : ''),
      isExternal: true,
      imageBlue: CDN_LINK + "/assets/images/car_guide_Blue_icon.svg",
      image: CDN_LINK + "/assets/images/car_guide_icon.svg",
      title: t("دليل سيارة", {
        ns: "web_aside_menu"
      }),
      scale: "scale(1.5)"
    }, {
      link: createUrl(CARS_PRICES_BASE_PATH),
      imageBlue: CDN_LINK + "/assets/images/cars-prices-Blue.svg",
      image: CDN_LINK + "/assets/images/cars-prices.svg",
      title: t("أسعار السيارات", {
        ns: "web_aside_menu"
      }),
      scale: "scale(1.1)"
    }, {
      link: createUrl("/network"),
      imageBlue: CDN_LINK + "/assets/images/affiliate_Blue_icon.svg",
      image: CDN_LINK + "/assets/images/affiliate_icon.svg",
      title: t("برنامج سيارة للتسويق بالعمولة", {
        ns: "web_aside_menu"
      }),
      scale: "scale(1.5)"
    }, {
      link: createUrl("/service-centers"),
      imageBlue: CDN_LINK + "/assets/images/111service_centers2_iconBlue.svg",
      image: CDN_LINK + "/assets/images/111service_centers2_icon.svg",
      title: t("مراكز الصيانة و الخدمات", {
        ns: "web_aside_menu"
      }),
      scale: "scale(1.5)"
    }, {
      link: "https://apply.workable.com/syarah/",
      isExternal: true,
      imageBlue: CDN_LINK + "/assets/images/Jobs@1Blue.svg",
      image: CDN_LINK + "/assets/images/Jobs@1.svg",
      title: t("وظائف", {
        ns: "web_aside_menu"
      }),
      scale: "scale(1.1)"
    }]
  }];
};
export default menuData;